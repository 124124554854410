<template>
  <b-row>
    <b-col>
      <div class="font-weight-bold font-medium-3 text-primary">
        Müşteri Şikayetleri
      </div>
      <div v-html="dataItem.complaints" />
    </b-col>
    <b-col>
      <div class="font-weight-bold font-medium-3 text-primary">
        Yapılan Uygulama
      </div>
      <div v-html="dataItem.transactions" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'ServiceSummary',
  components: {
    BRow, BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
}
</script>
