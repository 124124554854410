<template>
  <div>
    <b-card title="Makina Bilgileri">
      <machine />
    </b-card>
    <b-card title="Servis Özeti">
      <service-summary />
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Yedek Parçalar</b-card-title>
      </b-card-header>
      <spare-parts />
    </b-card>
    <b-card v-if="serviceFiles.length > 0" no-body>
      <b-card-header>
        <b-card-title>Dosya Eki</b-card-title>
      </b-card-header>
      <service-media />
    </b-card>
    <b-card title="Servis Detayı">
      <service-detail />
    </b-card>
    <mail-logs />
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import Machine from '@/views/Admin/Services/View/Machine.vue'
import ServiceSummary from '@/views/Admin/Services/View/ServiceSummary.vue'
import SpareParts from '@/views/Admin/Services/View/Spareparts.vue'
import ServiceDetail from '@/views/Admin/Services/View/ServiceDetail.vue'
import ServiceMedia from '@/views/Admin/Services/View/ServiceMedia.vue'
import MailLogs from '@/views/Admin/Services/View/MailLogs.vue'
export default {
  name: 'ServiceView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    Machine,
    ServiceSummary,
    SpareParts,
    ServiceDetail,
    ServiceMedia,
    MailLogs,
  },
  data() {
    return {
      emailForm: false,
      signType: null,
      signTitle: null,
      downloadUrl: `${this.$store.state.app.baseURL}/Exports/services/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}`,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
    serviceFiles() {
      return this.$store.getters['serviceFiles/dataList']
    },
    machine() {
      return this.$store.getters['customerMachines/dataItem']
    },
  },
  created() {
    this.getServiceFiles()
  },
  methods: {
    getServiceFiles() {
      this.$store.dispatch('serviceFiles/getDataList', this.$route.params.id)
    },
    toggleMailForm() {
      this.emailForm = !this.emailForm
    },
  },
}
</script>
