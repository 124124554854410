<template>
  <b-list-group v-if="machine.id">
    <b-list-group-item>
      <div class="text-primary font-weight-bold">
        Ürün Kartı
      </div>
      <div>{{ machine.product }}</div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="text-primary font-weight-bold">
        Tanım
      </div>
      <div>{{ machine.title }}</div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="text-primary font-weight-bold">
        Seri No
      </div>
      <div>{{ machine.serial }}</div>
    </b-list-group-item>
    <b-list-group-item v-if="machine.warranty_sdate || machine.warranty_edate">
      <div class="font-weight-bold text-primary">
        Makina Garanti Başlangıç - Bitiş
      </div>
      <div class="font-weight-light">
        {{ (machine.warranty_sdate? moment(machine.warranty_sdate).format('DD.MM.YYYY') : null) }}
        -
        {{ (machine.warranty_edate? moment(machine.warranty_edate).format('DD.MM.YYYY') : null) }}
      </div>
      <div class="font-weight-light text-success font-small-2">
        <span v-if="moment(machine.warranty_edate).diff(moment(),'days') > 0">
          {{ moment(machine.warranty_edate).diff(moment(),'days') }} gün kaldı
        </span>
        <span v-else>Garanti süresi bitmiş</span>
      </div>
    </b-list-group-item>
    <b-list-group-item v-if="machine.warranty_sdate || machine.warranty_edate">
      <div class="font-weight-bold text-primary">
        Kafa Garanti Başlangıç - Bitiş
      </div>
      <div class="font-weight-light">
        {{ (machine.head_warranty_sdate? moment(machine.head_warranty_sdate).format('DD.MM.YYYY') : null) }}
        -
        {{ (machine.head_warranty_edate? moment(machine.head_warranty_edate).format('DD.MM.YYYY') : null) }}
      </div>
      <div class="font-weight-light text-success font-small-2">
        <span v-if="moment(machine.head_warranty_edate).diff(moment(),'days') > 0">
          {{ moment(machine.head_warranty_edate).diff(moment(),'days') }} gün kaldı
        </span>
        <span v-else>Garanti süresi bitmiş</span>
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <div class="text-primary font-weight-bold">
        Durum
      </div>
      <div>{{ machine.status? 'Kullanımda' : 'Kullanım Dışı' }}</div>
    </b-list-group-item>
    <b-list-group-item v-if="machine.notes">
      <div class="text-primary font-weight-bold">
        Notlar
      </div>
      <div>{{ machine.notes }}</div>
    </b-list-group-item>
    <b-list-group-item v-if="machine.customer_address">
      <div class="text-primary font-weight-bold">
        Müşteri Adres Adı
      </div>
      <div>{{ machine.customer_address }}</div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'Machine',
  components: {
    BListGroup,
    BListGroupItem,
  },
  computed: {
    machine() {
      return this.$store.getters['customerMachines/dataItem']
    },
  },
}
</script>
