<template>
  <div>
    <service-view />
  </div>
</template>
<script>
import ServiceView from '@/views/Admin/Services/ServiceView.vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'View',
  components: {
    ServiceView,
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
    saveData() {
      return this.$store.getters['services/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  beforeCreate() {
    this.$store.commit('services/RESET_DATA_ITEM')
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('services/getDataItem', { id: this.$route.params.id, isUpdate: false })
        .then(response => {
          if (response.id_customers) {
            this.getCustomer(response.id_customers)
            this.getMachine(response.id_customer_machines)
          }
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },
    getMachine(id) {
      this.$store.dispatch('customerMachines/getDataItem', id)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
