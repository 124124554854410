<template>
  <div>
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th>
            YEDEK PARÇA
          </b-th>
          <b-th>
            MİKTAR
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="service_line in dataItem.service_lines"
          :key="service_line.id"
        >
          <b-td>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              pill
              @click="openModal(service_line.id_products)"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
            {{ service_line.product }}
          </b-td>
          <b-td class="text-nowrap">
            {{ service_line.quantity }} {{ service_line.unit }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-modal
      v-model="modalData.status"
      size="lg"
      centered
      title="Ürün Detayı"
      hide-footer
    >
      <template v-if="modalData.product">
        <b-table-simple
          hover
          striped
          bordered
        >
          <b-tbody>
            <b-tr>
              <b-th class="width-200 bg-light">
                Ürün Adı
              </b-th>
              <b-td>{{ modalData.product.title }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="width-200 bg-light">
                Açıklama
              </b-th>
              <b-td><div v-html="modalData.product.content" /></b-td>
            </b-tr>
            <b-tr>
              <b-th class="width-200 bg-light">
                Marka
              </b-th>
              <b-td>{{ modalData.product.brand }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="width-200 bg-light">
                Ürün Grubu
              </b-th>
              <b-td>
                <div>{{ modalData.product.product_group }}</div>
                <div class="text-muted font-small-2">
                  {{ modalData.product.main_product_group }}
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row v-if="modalData.product.images.length > 0">
          <b-col
            v-for="image in modalData.product.images"
            :key="image.id"
            cols="12"
          >
            <b-img
              :src="baseUrl + '/media/products/' + image.filename"
              fluid-grow
            />
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTableSimple, BTbody, BTd, BTh, BThead, BTr, BButton, BModal, BImg, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Spareparts',
  components: {
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTbody,
    BTd,
    BButton,
    BModal,
    BImg,
    BRow,
    BCol,
  },
  data() {
    return {
      baseUrl: this.$store.state.app.baseURL,
      modalData: {
        status: false,
        product: null,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
  methods: {
    openModal(id) {
      this.modalData.status = true
      this.$store.dispatch('products/getDataItem', id)
        .then(response => {
          if (response) {
            this.modalData.product = response
          }
        })
    },
  },
}
</script>
