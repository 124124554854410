<template>
  <div>
    <b-table
      v-if="dataList.length > 0"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        <div class="text-primary">
          <b-link
            :href="baseURL + data.item.filename"
            target="_blank"
          >
            {{ data.item.title }}
          </b-link>
          <div class="text-muted font-small-2">
            {{ moment(data.item.created).format('llll') }} tarihinde
            {{ data.item.username }} tarafından yüklendi.
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable,
  BLink,
} from 'bootstrap-vue'
export default {
  name: 'ServiceMedia',
  components: {
    BTable,
    BLink,
  },
  data() {
    return {
      baseURL: `${this.$store.state.app.baseURL}/media/services/`,
      fields: [
        {
          key: 'title',
          label: 'DOSYA',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceFiles/dataList']
    },
    dataItem() {
      return this.$store.getters['serviceFiles/dataItem']
    },
  },
}
</script>
