<template>
  <b-list-group>
    <b-list-group-item>
      <b-row>
        <b-col>
          <div class="text-primary font-weight-bold">
            Firma Yetkilisi
          </div>
          <div>{{ dataItem.customer_person }}</div>
        </b-col>
        <b-col>
          <div class="text-primary font-weight-bold">
            Firma Teknisyeni
          </div>
          <div>{{ dataItem.customer_technician }}</div>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col>
          <div class="text-primary font-weight-bold">
            Servis İşlemi
          </div>
          <div>{{ dataItem.service_type }}</div>
        </b-col>
        <b-col>
          <div class="text-primary font-weight-bold">
            Servis İşlem Durumu
          </div>
          <div>{{ dataItem.service_status }}</div>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item>
      <div class="text-primary font-weight-bold">
        Servis Ekibi
      </div>
      <div>
        <b-badge
          v-for="crew in dataItem.service_crew"
          :key="crew.id"
          variant="primary"
          pill
          class="mr-1 px-1"
        >
          {{ crew.person_name }}
        </b-badge>
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <b-row>
        <b-col>
          <div class="text-primary font-weight-bold">
            Başlangıç Tarih Saati
          </div>
          <div>{{ dataItem.sdate? moment(dataItem.sdate).format('LLLL') : '-' }}</div>
        </b-col>
        <b-col>
          <div class="text-primary font-weight-bold">
            Bitiş Tarih Saati
          </div>
          <div>{{ dataItem.edate? moment(dataItem.edate).format('LLLL') : '-' }}</div>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item v-if="dataItem.notes">
      <div class="text-primary font-weight-bold">
        Notlar
      </div>
      <div>{{ dataItem.notes }}</div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {
  BCol, BListGroup, BListGroupItem, BRow, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'ServiceDetail',
  components: {
    BRow,
    BCol,
    BBadge,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
}
</script>
